import React from 'react';
import style from './Palette.module.css'

const inactiveEraser = (
    <svg width="96" height="100" viewBox="0 0 96 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.currentColorItemSVG}>
        <path
            d="M57.7494 97.5158L92.7961 62.4674C94.398 60.8652 95.2803 58.7357 95.2803 56.4705C95.2803 54.2053 94.398 52.0758 92.7961 50.474L91.4933 49.1711C90.9213 48.5988 89.9939 48.5988 89.4219 49.1709C88.8498 49.743 88.8496 50.6705 89.4217 51.2424L90.7244 52.5455C91.773 53.5939 92.3506 54.9879 92.3506 56.4705C92.3506 57.9531 91.773 59.3469 90.7244 60.3959L55.6777 95.4443C54.6291 96.493 53.2349 97.0703 51.7517 97.0703C50.2689 97.0703 48.8754 96.4932 47.8273 95.4443L15.9035 63.5203C14.8551 62.4727 14.2777 61.0789 14.2777 59.5961C14.2777 58.1141 14.8547 56.7205 15.9019 55.6723L50.9525 20.6234C52.0012 19.5748 53.3951 18.9973 54.8777 18.9973C56.3601 18.9973 57.7541 19.5748 58.8029 20.6234L85.2773 47.0982C85.8494 47.6703 86.7769 47.6703 87.3488 47.0982C87.9209 46.526 87.9209 45.5986 87.3488 45.0266L60.8744 18.5521C59.2724 16.95 57.1428 16.0678 54.8777 16.0678C54.0846 16.0678 53.3088 16.1783 52.5656 16.3877L39.5658 3.38828C37.381 1.20332 34.476 0 31.3855 0C28.2953 0 25.39 1.20332 23.2053 3.38809L7.97674 18.6164C7.40486 19.1885 7.40447 20.1158 7.97674 20.6881C8.54881 21.2602 9.47635 21.2602 10.0482 20.6881L25.2767 5.45977C26.9084 3.82832 29.0777 2.92969 31.3855 2.92969C33.6933 2.92969 35.8629 3.82832 37.4943 5.45977L49.7338 17.6992L13.8322 53.5988C13.8312 53.5998 13.8305 53.6008 13.8295 53.6018L12.9793 54.452L5.62049 47.093C4.3492 45.8217 3.6492 44.1324 3.6492 42.3359V29.873C3.6492 28.0766 4.3492 26.3871 5.62069 25.1158L5.91756 24.8187C6.48963 24.2463 6.48924 23.3188 5.91717 22.7473C5.3451 22.1752 4.41756 22.1754 3.84568 22.7475L3.54901 23.0443C1.7244 24.8691 0.719516 27.2941 0.719516 29.8732V42.3359C0.719516 44.915 1.7244 47.34 3.54901 49.1648L11.668 57.2838C11.4586 58.027 11.348 58.8031 11.348 59.5965C11.348 61.8623 12.2305 63.9918 13.8322 65.5926L45.7556 97.5156C47.3568 99.1178 49.4863 100 51.7519 100C54.0174 100 56.1474 99.1178 57.7494 97.5158V97.5158Z"
            fill="black"/>
    </svg>
)

type EraserProps = {
    onClick: () => void
    isActive: boolean
}

const Eraser = ({onClick, isActive}: EraserProps) => {
    return (
        <div onClick={onClick} className={style.currentColorItem}>
            {isActive ?
                (<svg width="96" height="100" viewBox="0 0 96 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.currentColorItemSVG}>
                    <path
                        d="M11.9221 56.7208L40.6605 48.1288L51.9191 16.7238L38.2441 3.04896C34.1791 -1.01608 27.5881 -1.01608 23.523 3.04896L3.27401 23.298C1.691 24.881 0.80174 27.0279 0.80174 29.2663V42.1044C0.80174 44.3431 1.691 46.49 3.27401 48.0728L11.9221 56.7208Z"
                        fill="#C5F4FC"/>
                    <path
                        d="M14.4814 12.0911L23.5228 3.04955C27.5885 -1.01608 34.1799 -1.01451 38.2441 3.04955L51.9201 16.7255L42.8785 25.7671L29.2025 12.0911C25.1385 8.02689 18.5471 8.02552 14.4814 12.0911V12.0911Z"
                        fill="#7DDDFA"/>
                    <path
                        d="M56.9747 97.8829L93.0808 61.7769C95.9034 58.9542 95.9034 54.3781 93.0808 51.5554L60.1945 18.6691C57.3718 15.8464 52.7956 15.8464 49.973 18.6691L13.8669 54.7751C11.0443 57.5978 11.0443 62.174 13.8669 64.9966L46.7532 97.8829C49.5759 100.706 54.1521 100.706 56.9747 97.8829V97.8829Z"
                        fill="#E696A6"/>
                    <path
                        d="M41.6787 26.964L49.9732 18.6694C52.7961 15.8466 57.3724 15.8466 60.1953 18.6694L93.0814 51.5556C95.9043 54.3784 95.9043 58.9548 93.0814 61.7777L84.7869 70.0722C87.6082 67.2509 87.6082 62.6743 84.7853 59.8517L51.8992 26.9655C49.0763 24.1429 44.5 24.1427 41.6787 26.964Z"
                        fill="#E17888"/>
                </svg>) : inactiveEraser
            }

        </div>
    );
};

export default Eraser;