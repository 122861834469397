import React, {MouseEventHandler} from 'react';
import gsap from 'gsap'
import style from './MainHeader.module.css'


const onMouseOver:MouseEventHandler =  (e) => {
    if (!gsap.isTweening(e.currentTarget)) {
        gsap.to(e.currentTarget, {duration: 0.5, attr: {y: -50}});
        gsap.to(e.currentTarget, {duration: 0.5, attr: {y: 0}, delay: 0.5});
    }
}

const Letters = () => {

    return (
        <a title='На главную страницу' href='/'>
            <div className={style.lettersContainer}>
                <svg width="321" height="82" viewBox="0 -10 321 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <svg id="l1" onMouseOver={onMouseOver}>
                        <path id="Vector" d="M12.488 12.2431C11.4145 12.4817 10.5951 13.0312 10.0298 13.8916C9.99927 13.9556 9.96083 14.0149 9.91447 14.0695C9.35977 14.9368 9.19258 15.9092 9.4129 16.9866L11.6756 27.5905C12.149 29.828 12.4903 31.9862 12.6995 34.065C12.7212 34.2454 12.756 34.4083 12.8037 34.5537C12.9537 36.4293 12.9835 38.2452 12.8932 40.0013L12.8828 39.9525C12.7904 41.417 12.6031 42.8534 12.3207 44.2619L12.3103 44.213C12.1571 45.002 11.9644 45.7754 11.7325 46.5332L10.5805 46.8812C9.53064 47.1849 8.74489 47.8124 8.22328 48.7637L8.26172 48.7044C7.73814 49.6184 7.58724 50.5873 7.80901 51.611L11.738 68.348C11.9557 69.3815 12.5215 70.1975 13.4354 70.7958C13.4822 70.8174 13.5182 70.8267 13.5436 70.8238C14.4645 71.38 15.4369 71.5472 16.4606 71.3254L23.253 69.876C24.278 69.6607 25.0974 69.1111 25.7113 68.2275C25.7356 68.2198 25.7484 68.2 25.7497 68.1682C26.3235 67.328 26.5302 66.3812 26.3699 65.328L25.3284 59.0106L41.0145 55.6635L42.6423 61.8558C42.9323 62.9013 43.5435 63.6905 44.4759 64.2235L44.3678 64.1955C45.2816 64.7819 46.2737 64.9619 47.3441 64.7355L54.0876 63.2965C55.1126 63.0812 55.932 62.5316 56.5459 61.648C56.5702 61.6403 56.583 61.6205 56.5843 61.5887C57.2068 60.6625 57.4066 59.6832 57.1836 58.6508L54.0365 41.747C53.8165 40.7528 53.3031 39.9427 52.4961 39.3167C52.4942 39.3102 52.4744 39.2974 52.4368 39.2783C51.5697 38.6447 50.5998 38.4088 49.5269 38.5708L49.2233 38.5845L43.0818 9.8023C42.846 8.68057 42.2732 7.83205 41.3635 7.25674C40.4435 6.66633 39.4351 6.48981 38.3383 6.72716L12.488 12.2431ZM28.2185 26.1043L27.9266 24.736L28.7573 24.5587L32.4172 41.7108L29.1432 42.4094C29.2113 41.819 29.256 41.2305 29.2773 40.6437C29.4388 38.3476 29.4443 35.9792 29.2939 33.5385L29.2835 33.4896C29.0955 31.0274 28.7406 28.5657 28.2185 26.1043Z" fill="url(#paint0_linear_505_18)"/>
                        <path id="Vector_2" d="M50.621 40.5854C50.3839 40.5182 50.1257 40.5052 49.8463 40.5463C49.8224 40.5524 49.7899 40.5593 49.7486 40.5672L49.4449 40.5809C48.9506 40.6554 48.4819 40.534 48.0389 40.2167C47.6357 39.9298 47.379 39.5248 47.2687 39.0016L41.1271 10.2194C41.0105 9.66842 40.734 9.25057 40.2976 8.96582C39.8317 8.65531 39.3176 8.56064 38.7554 8.68181L12.9051 14.1977C12.3861 14.3056 11.981 14.5623 11.69 14.9679C11.6892 15.0099 11.6799 15.0459 11.662 15.0761C11.6418 15.1045 11.6127 15.1277 11.5747 15.1458C11.3232 15.5771 11.2541 16.0516 11.3675 16.5695L13.6302 27.1735C14.1236 29.4865 14.4823 31.7261 14.7062 33.8923C14.7529 34.0583 14.7713 34.2247 14.7617 34.3913C14.9342 36.3511 14.9814 38.2484 14.9033 40.0833C14.901 40.1492 14.8951 40.2015 14.8857 40.2404C14.7763 41.6311 14.5878 42.9826 14.3203 44.295C14.3296 44.4177 14.3214 44.5387 14.2956 44.6579C14.1141 45.4702 13.9121 46.2797 13.6898 47.0863C13.5986 47.4208 13.4226 47.7138 13.162 47.9653C12.9277 48.2125 12.639 48.3763 12.2961 48.4566L11.1931 48.7942C17.77 51.1715 24.7853 51.565 32.2391 49.9745C39.5484 48.4148 45.6757 45.2851 50.621 40.5854ZM27.5095 22.7813L28.3402 22.6041C28.8827 22.4846 29.377 22.5665 29.8232 22.8497C30.3032 23.1669 30.5994 23.5975 30.7119 24.1416L34.3719 41.2937C34.4912 41.8362 34.3931 42.334 34.0774 42.7871C33.7927 43.2602 33.3783 43.553 32.8343 43.6654L29.5602 44.3641C29.1367 44.4503 28.7179 44.4034 28.304 44.2234C27.9231 44.0435 27.6255 43.7664 27.4114 43.392C27.1914 43.0128 27.1045 42.6056 27.1507 42.1704C27.2169 41.6143 27.2685 41.0583 27.3057 40.5024C27.4472 38.2922 27.4538 36.0087 27.3256 33.6519C27.1252 31.2762 26.7713 28.8994 26.2639 26.5213L25.9719 25.1531C25.8524 24.6105 25.9308 24.0998 26.2071 23.6213C26.5312 23.1737 26.9653 22.8937 27.5095 22.7813Z" fill="url(#paint1_linear_505_18)"/>
                    </svg>
                    <svg id="l2" onMouseOver={onMouseOver}>
                        <path id="Vector_3" d="M79.9 4H79.85C75.4702 4.07201 71.6702 5.12201 68.45 7.15C65.2047 9.13451 62.6714 11.8678 60.85 15.35V15.4C59.1156 18.8569 58.2323 22.7569 58.2 27.1V27.15C58.2349 31.4201 59.0682 35.2034 60.7 38.5C62.3663 41.965 64.7663 44.6983 67.9 46.7V46.65C71.0378 48.7117 74.7878 49.7617 79.15 49.8H79.2C83.5919 49.76 87.4086 48.7934 90.65 46.9L90.6 46.95C93.9284 45.0962 96.4951 42.3962 98.3 38.85C100.132 35.453 101.066 31.353 101.1 26.55C101.093 22.4296 100.277 18.6796 98.65 15.3C97.0179 11.9145 94.6179 9.19779 91.45 7.15C88.3162 5.12669 84.4662 4.07669 79.9 4ZM86.4 20.7C87.0699 22.577 87.4033 24.6103 87.4 26.8C87.4033 29.1809 87.0699 31.2975 86.4 33.15C85.7879 34.8384 84.8879 36.1551 83.7 37.1C83.6945 37.1336 83.6779 37.1503 83.65 37.15C82.5772 38.056 81.2606 38.5227 79.7 38.55H79.65C78.1212 38.5177 76.7879 38.0677 75.65 37.2H75.7C74.5095 36.2583 73.5762 34.975 72.9 33.35C72.9069 33.334 72.9069 33.3173 72.9 33.3C72.2345 31.4781 71.9012 29.3781 71.9 27C71.8999 24.6286 72.2332 22.512 72.9 20.65C73.5455 18.9616 74.4621 17.6283 75.65 16.65C76.7796 15.7531 78.1129 15.3031 79.65 15.3H79.7C81.2901 15.3271 82.6234 15.7771 83.7 16.65C84.9141 17.69 85.8141 19.04 86.4 20.7Z" fill="url(#paint2_linear_505_18)"/>
                        <path id="Vector_4" d="M85.1 15.15H85.15C86.5834 16.3833 87.6667 18.0167 88.4 20.05C89.1334 22.1167 89.5 24.3667 89.5 26.8C89.5 27.4392 89.4667 28.0559 89.4 28.65C92.7655 28.9857 95.9488 29.5523 98.95 30.35C98.9866 30.0422 99.0199 29.7255 99.05 29.4C99.1427 28.4962 99.1927 27.5629 99.2 26.6V26.55C99.2 22.75 98.45 19.3 96.95 16.2C95.4834 13.1333 93.3334 10.6833 90.5 8.85C87.6334 7.01667 84.15 6.06667 80.05 6H80C76 6.06667 72.55 7.01667 69.65 8.85H69.6C66.6667 10.65 64.3834 13.1333 62.75 16.3V16.35C61.15 19.55 60.3334 23.15 60.3 27.15V27.2C60.3064 27.9487 60.3397 28.682 60.4 29.4C60.4806 30.3564 60.5973 31.273 60.75 32.15C63.6936 30.9999 66.8103 30.0999 70.1 29.45C70.1189 29.4398 70.1189 29.4232 70.1 29.4C70.0334 28.6298 70 27.8298 70 27C70 24.3667 70.3834 22.0167 71.15 19.95C71.9167 17.8833 73.0334 16.2833 74.5 15.15L74.35 15.25C74.4167 15.1833 74.4834 15.1333 74.55 15.1C75.9834 13.9333 77.7334 13.3333 79.8 13.3H79.85C81.9167 13.3333 83.6667 13.95 85.1 15.15Z" fill="url(#paint3_linear_505_18)"/>
                    </svg>
                    <svg id="l3" onMouseOver={onMouseOver}>
                        <path id="Vector_5" d="M129.158 6.80062C128.102 6.49233 127.12 6.57879 126.21 7.06001C126.152 7.10135 126.09 7.13476 126.023 7.16025C125.119 7.65264 124.504 8.4238 124.178 9.47372L121.05 19.8527C120.386 22.0408 119.645 24.0957 118.827 26.0175C118.759 26.186 118.711 26.3454 118.683 26.4957C117.91 28.211 117.061 29.816 116.136 31.3108L116.15 31.2629C115.363 32.5013 114.507 33.6692 113.581 34.7669L113.595 34.7191C113.081 35.3363 112.539 35.9209 111.971 36.473L110.794 36.2227C109.728 35.9828 108.738 36.1537 107.822 36.7356L107.885 36.7022C106.985 37.2505 106.386 38.0265 106.087 39.0301L101.463 55.5843C101.156 56.5946 101.258 57.582 101.77 58.5465C101.801 58.5879 101.828 58.6135 101.851 58.6232C102.39 59.5542 103.161 60.1692 104.165 60.4683L110.813 62.4721C111.815 62.7774 112.797 62.6909 113.761 62.2127C113.786 62.2176 113.807 62.2065 113.823 62.1793C114.731 61.7198 115.368 60.9901 115.735 59.9903L117.867 53.9546L133.221 58.5821L131.662 64.7907C131.413 65.8463 131.568 66.8322 132.128 67.7483L132.046 67.6717C132.564 68.6257 133.346 69.2615 134.393 69.579L140.994 71.5684C141.995 71.8737 142.978 71.7872 143.941 71.309C143.966 71.314 143.987 71.3028 144.004 71.2756C144.995 70.7643 145.642 70.0027 145.944 68.991L151.334 52.6674C151.62 51.6905 151.561 50.7335 151.156 49.7963C151.157 49.7897 151.146 49.7689 151.122 49.734C150.668 48.7611 149.932 48.0871 148.914 47.712L148.642 47.5777L157.133 19.4062C157.467 18.31 157.374 17.2907 156.854 16.3483C156.333 15.3878 155.535 14.7472 154.46 14.4266L129.158 6.80062ZM136.257 26.5231L136.661 25.1839L137.474 25.4289L132.414 42.2171L129.209 41.2512C129.554 40.7669 129.876 40.2729 130.178 39.7692C131.426 37.8358 132.572 35.7638 133.616 33.5533L133.631 33.5055C134.653 31.2582 135.528 28.9307 136.257 26.5231Z" fill="url(#paint4_linear_505_18)"/>
                        <path id="Vector_6" d="M148.902 50.0039C148.727 49.8309 148.507 49.695 148.242 49.5964C148.218 49.5902 148.186 49.5805 148.146 49.5675L147.874 49.4332C147.405 49.2602 147.053 48.928 146.818 48.4366C146.603 47.991 146.573 47.5125 146.729 47.0011L155.219 18.8295C155.383 18.2907 155.342 17.7915 155.097 17.3317C154.838 16.8352 154.434 16.5045 153.883 16.3397L128.581 8.7138C128.075 8.55812 127.596 8.58781 127.146 8.80287C127.125 8.83924 127.099 8.86632 127.069 8.88412C127.038 8.89926 127.001 8.9056 126.959 8.90311C126.531 9.15967 126.242 9.54208 126.091 10.0504L122.963 20.4293C122.281 22.6932 121.516 24.8279 120.668 26.8332C120.629 27.0012 120.565 27.1558 120.476 27.2971C119.683 29.0969 118.81 30.7816 117.857 32.3513C117.823 32.4078 117.793 32.4508 117.766 32.4803C117 33.6458 116.183 34.7389 115.317 35.7596C115.266 35.8715 115.2 35.9735 115.12 36.0656C114.569 36.6896 114.003 37.3013 113.419 37.9007C113.178 38.1498 112.883 38.3217 112.533 38.4163C112.209 38.5199 111.877 38.5243 111.538 38.4294L110.409 38.1935C115.024 43.4455 120.98 47.1709 128.275 49.3698C135.43 51.5261 142.305 51.7375 148.902 50.0039ZM135.692 23.3787C136.191 23.1429 136.707 23.1069 137.237 23.2707L138.05 23.5157C138.583 23.6725 138.977 23.9824 139.231 24.4455C139.499 24.9547 139.551 25.4747 139.387 26.0056L134.327 42.7937C134.17 43.3264 133.844 43.7152 133.349 43.9599C132.872 44.2371 132.368 44.2939 131.837 44.1302L128.633 43.1644C128.22 43.0358 127.876 42.7929 127.6 42.4358C127.353 42.0946 127.226 41.7085 127.219 41.2774C127.209 40.8392 127.329 40.4406 127.579 40.0816C127.905 39.6265 128.218 39.1643 128.519 38.6952C129.708 36.8274 130.814 34.8303 131.838 32.7041C132.807 30.5266 133.642 28.274 134.344 25.9465L134.748 24.6072C134.904 24.0743 135.219 23.6648 135.692 23.3787Z" fill="url(#paint5_linear_505_18)"/>
                    </svg>
                    <svg id="l4" onMouseOver={onMouseOver}>
                        <path id="Vector_7" d="M183.9 14.05H183.85C179.47 14.122 175.67 15.172 172.45 17.2C169.205 19.1845 166.671 21.9178 164.85 25.4V25.45C163.116 28.9069 162.232 32.8069 162.2 37.15V37.2C162.235 41.47 163.068 45.2534 164.7 48.55C166.366 52.015 168.766 54.7483 171.9 56.75V56.7C175.038 58.7617 178.788 59.8117 183.15 59.85H183.2C187.592 59.81 191.409 58.8433 194.65 56.95L194.6 57C197.928 55.1462 200.495 52.4462 202.3 48.9C204.132 45.503 205.066 41.403 205.1 36.6C205.093 32.4795 204.277 28.7295 202.65 25.35C201.018 21.9644 198.618 19.2478 195.45 17.2C192.316 15.1767 188.466 14.1267 183.9 14.05ZM190.4 30.75C191.07 32.6269 191.403 34.6603 191.4 36.85C191.403 39.2308 191.07 41.3475 190.4 43.2C189.788 44.8884 188.888 46.2051 187.7 47.15C187.695 47.1836 187.678 47.2002 187.65 47.2C186.577 48.106 185.261 48.5726 183.7 48.6H183.65C182.121 48.5677 180.788 48.1177 179.65 47.25H179.7C178.51 46.3083 177.576 45.025 176.9 43.4C176.907 43.384 176.907 43.3673 176.9 43.35C176.235 41.5281 175.901 39.4281 175.9 37.05C175.9 34.6786 176.233 32.562 176.9 30.7C177.545 29.0116 178.462 27.6782 179.65 26.7C180.78 25.8031 182.113 25.3531 183.65 25.35H183.7C185.29 25.3771 186.623 25.8271 187.7 26.7C188.914 27.74 189.814 29.09 190.4 30.75Z" fill="url(#paint6_linear_505_18)"/>
                        <path id="Vector_8" d="M189.1 25.2H189.15C190.583 26.4333 191.667 28.0667 192.4 30.1C193.133 32.1667 193.5 34.4167 193.5 36.85C193.5 37.4892 193.467 38.1058 193.4 38.7C196.766 39.0357 199.949 39.6023 202.95 40.4C202.987 40.0922 203.02 39.7755 203.05 39.45C203.143 38.5462 203.193 37.6129 203.2 36.65V36.6C203.2 32.8 202.45 29.35 200.95 26.25C199.483 23.1833 197.333 20.7333 194.5 18.9C191.633 17.0667 188.15 16.1167 184.05 16.05H184C180 16.1167 176.55 17.0667 173.65 18.9H173.6C170.667 20.7 168.383 23.1833 166.75 26.35V26.4C165.15 29.6 164.333 33.2 164.3 37.2V37.25C164.306 37.9987 164.34 38.732 164.4 39.45C164.481 40.4064 164.597 41.323 164.75 42.2C167.694 41.0499 170.81 40.1499 174.1 39.5C174.119 39.4898 174.119 39.4732 174.1 39.45C174.033 38.6798 174 37.8798 174 37.05C174 34.4167 174.383 32.0667 175.15 30C175.917 27.9333 177.033 26.3333 178.5 25.2L178.35 25.3C178.417 25.2333 178.483 25.1833 178.55 25.15C179.983 23.9833 181.733 23.3833 183.8 23.35H183.85C185.917 23.3833 187.667 24 189.1 25.2Z" fill="url(#paint7_linear_505_18)"/>
                    </svg>
                    <svg id="l5" onMouseOver={onMouseOver}>
                        <path id="Vector_9" d="M255.4 0.949996C255.134 0.488928 254.734 0.188928 254.2 0.0499955C253.663 -0.085291 253.146 -0.0186243 252.65 0.249995L244.95 4.8C244.498 5.10026 244.198 5.51692 244.05 6.05C243.924 6.58645 243.991 7.08645 244.25 7.55L259.95 34.15L254.75 37.2L239.05 10.6C238.784 10.1387 238.384 9.83867 237.85 9.7C237.314 9.5638 236.798 9.63046 236.3 9.9L228.65 14.45C228.2 14.7499 227.9 15.1665 227.75 15.7C227.625 16.2365 227.691 16.7365 227.95 17.2L243.65 43.8L238.5 46.8L222.8 20.2C222.534 19.7389 222.134 19.4389 221.6 19.3C221.063 19.1648 220.546 19.2315 220.05 19.5L212.35 24.05C211.898 24.3503 211.598 24.7669 211.45 25.3C211.324 25.8366 211.391 26.3366 211.65 26.8L232.15 61.55C232.449 62.0444 232.866 62.3611 233.4 62.5C233.937 62.6353 234.437 62.5519 234.9 62.25L275.2 38.45C275.685 38.1829 275.985 37.7829 276.1 37.25C276.259 36.7134 276.192 36.1967 275.9 35.7L255.4 0.949996Z" fill="url(#paint8_linear_505_18)"/>
                        <path id="Vector_10" d="M242.4 37.75C245.671 37.5061 249.021 37.3394 252.45 37.25L237.35 11.65L229.7 16.2L242.4 37.75Z" fill="url(#paint9_linear_505_18)"/>
                        <path id="Vector_11" d="M213.4 25.8L222.3 40.95C225.315 40.2191 228.415 39.5858 231.6 39.05L221.1 21.25L213.4 25.8Z" fill="url(#paint10_linear_505_18)"/>
                        <path id="Vector_12" d="M258.55 37.25C263.234 37.3296 267.734 37.5796 272.05 38L274.15 36.75H274.2V36.7L253.7 2L246 6.55L261.65 33.1C261.945 33.5987 262.011 34.1154 261.85 34.65C261.727 35.185 261.427 35.585 260.95 35.85L258.55 37.25Z" fill="url(#paint11_linear_505_18)"/>
                    </svg>
                    <svg id="l6" onMouseOver={onMouseOver}>
                        <path id="Vector_13" d="M293.35 0.4C292.995 0.663672 292.745 0.997005 292.6 1.4L280.3 41.85C280.193 42.2749 280.209 42.6915 280.35 43.1C280.487 43.5038 280.72 43.8204 281.05 44.05C281.418 44.3165 281.818 44.4499 282.25 44.45H291.65C292.094 44.453 292.494 44.3197 292.85 44.05C293.211 43.7704 293.444 43.4038 293.55 42.95L296.05 34.05H304.6L307.3 43C307.445 43.437 307.695 43.787 308.05 44.05C308.407 44.315 308.807 44.4483 309.25 44.45H319.15C319.581 44.451 319.964 44.3177 320.3 44.05C320.665 43.8115 320.915 43.4781 321.05 43.05C321.183 42.6589 321.183 42.2589 321.05 41.85L308.45 1.4C308.32 0.989714 308.07 0.65638 307.7 0.4C307.365 0.136198 306.982 0.00286458 306.55 0H294.55C294.107 0.00169271 293.707 0.135026 293.35 0.4ZM300.15 17.15C300.192 17.0763 300.225 16.993 300.25 16.9C300.276 16.9927 300.292 17.076 300.3 17.15V17.2L302.1 23.2H298.5L300.15 17.2V17.15Z" fill="url(#paint12_linear_505_18)"/>
                        <path id="Vector_14" d="M306.95 3.4C306.835 2.98724 306.602 2.65391 306.25 2.4C305.899 2.13711 305.499 2.00378 305.05 2H296.05C295.607 2.00182 295.207 2.13516 294.85 2.4C294.495 2.6638 294.245 2.99714 294.1 3.4L285.35 32.15C290.668 31.55 296.234 31.25 302.05 31.25C306.826 31.25 311.426 31.45 315.85 31.85L306.95 3.4ZM303.4 24.7C303.366 24.7477 303.316 24.781 303.25 24.8C302.914 25.0659 302.531 25.1992 302.1 25.2H298.5C298.107 25.1962 297.74 25.0796 297.4 24.85C297.379 24.8535 297.346 24.8368 297.3 24.8C296.993 24.5823 296.776 24.2823 296.65 23.9L296.6 23.85C296.471 23.4632 296.455 23.0632 296.55 22.65V22.6C296.574 22.5779 296.59 22.5445 296.6 22.5L298.2 16.9C298.209 16.8496 298.209 16.7996 298.2 16.75V16.7L298.25 16.65C298.292 16.5654 298.325 16.4654 298.35 16.35C298.455 15.9441 298.672 15.6108 299 15.35V15.3C299.378 15.038 299.795 14.9047 300.25 14.9C300.644 14.8949 300.994 14.9949 301.3 15.2C301.359 15.2349 301.409 15.2682 301.45 15.3C301.811 15.5784 302.044 15.9284 302.15 16.35C302.192 16.4594 302.225 16.5594 302.25 16.65C302.26 16.6861 302.26 16.7194 302.25 16.75L304 22.6C304.139 23.0254 304.139 23.4421 304 23.85C303.887 24.2003 303.687 24.4836 303.4 24.7Z" fill="url(#paint13_linear_505_18)"/>
                    </svg>
                    <defs>
                        <linearGradient id="paint0_linear_505_18" x1="23.1305" y1="9.92112" x2="35.3927" y2="67.3879" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#00FF33"/>
                            <stop offset="1" stopColor="#67FE85"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_505_18" x1="23.8726" y1="11.2444" x2="32.2769" y2="50.6306" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.341176"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_505_18" x1="79.65" y1="49.8" x2="79.65" y2="4" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FEBCE4"/>
                            <stop offset="1" stopColor="#FF0099"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_505_18" x1="79.75" y1="5.35" x2="79.75" y2="32.85" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_505_18" x1="139.599" y1="9.89543" x2="122.646" y2="66.1428" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0000FF"/>
                            <stop offset="1" stopColor="#5F5FFE"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_505_18" x1="139.611" y1="11.4122" x2="127.992" y2="49.9628" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.152941"/>
                        </linearGradient>
                        <linearGradient id="paint6_linear_505_18" x1="183.65" y1="59.85" x2="183.65" y2="14.05" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FEFDAB"/>
                            <stop offset="1" stopColor="#FFFF00"/>
                        </linearGradient>
                        <linearGradient id="paint7_linear_505_18" x1="183.75" y1="15.4" x2="183.75" y2="42.9" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.792157"/>
                        </linearGradient>
                        <linearGradient id="paint8_linear_505_18" x1="242.488" y1="9.0375" x2="245.213" y2="53.5625" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#00FFFF"/>
                            <stop offset="1" stopColor="#9AFEFD"/>
                        </linearGradient>
                        <linearGradient id="paint9_linear_505_18" x1="240.5" y1="13.3125" x2="241.7" y2="36.0875" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.301961"/>
                        </linearGradient>
                        <linearGradient id="paint10_linear_505_18" x1="222.137" y1="21.9875" x2="222.862" y2="40.2125" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.301961"/>
                        </linearGradient>
                        <linearGradient id="paint11_linear_505_18" x1="259.9" y1="5.9" x2="260.3" y2="34.1" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.301961"/>
                        </linearGradient>
                        <linearGradient id="paint12_linear_505_18" x1="300.762" y1="1.15" x2="300.737" y2="43.35" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FF9900"/>
                            <stop offset="1" stopColor="#FEC570"/>
                        </linearGradient>
                        <linearGradient id="paint13_linear_505_18" x1="300.612" y1="1.875" x2="300.587" y2="32.325" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.45098"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </a>
    );
};

export default Letters;
