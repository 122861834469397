import React from 'react';
import {useAppSelector} from "../../../__data__/hooks";
import {getCurrentColor} from "../../../__data__/slices/paintSlice";
import style from './Palette.module.css'


const inactiveBrush = (
    <svg width="100" height="94" viewBox="0 0 100 94" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.currentColorItemSVG}>
        <path d="M93.2976 93.4622C94.8128 93.0533 96.3196 92.1011 97.5395 90.7812C100.243 87.8569 100.793 84.0574 98.8182 81.9449L83.9295 65.9948C83.3702 65.3952 82.4303 65.3631 81.8306 65.9224C81.2309 66.4824 81.1989 67.4215 81.7581 68.0212L96.6477 83.972C97.3946 84.7715 97.049 86.936 95.3583 88.7648C94.5252 89.6666 93.4921 90.3326 92.5217 90.5951C91.7473 90.8049 91.0759 90.7438 90.6784 90.428L51.8341 59.4923L62.7617 47.6706L77.9518 63.9433C78.5118 64.543 79.451 64.5758 80.0507 64.0158C80.6503 63.4558 80.6832 62.5166 80.1232 61.9169L47.9454 27.4458C49.247 22.7163 48.1835 17.6085 44.9646 13.2948C40.6937 7.57123 33.4275 4.19674 26.001 4.48819C13.8619 4.96502 6.89319 4.46225 2.49332 0.394254C2.05005 -0.0146811 1.4046 -0.115389 0.858337 0.13867C0.332672 0.383573 3.02791e-05 0.909239 3.02791e-05 1.48449C3.02791e-05 1.50738 0.000793267 1.53103 0.00155616 1.55469C0.0282591 2.12536 0.712616 15.666 5.45581 27.7861C5.75488 28.5498 6.617 28.9267 7.38071 28.6276C8.14441 28.3285 8.5213 27.4672 8.22223 26.7035C5.00033 18.4729 3.71173 9.32141 3.23108 4.60797C8.26343 7.52163 15.2947 7.88098 26.117 7.45602C32.5806 7.20273 38.8901 10.1202 42.5843 15.0709C45.3789 18.8162 46.2174 23.0619 44.9631 27.0613L30.0156 43.231C25.7889 43.9245 21.9689 43.2295 18.6577 41.1528C15.6594 39.2736 12.9921 36.2318 10.7285 32.1127C10.3333 31.394 9.42996 31.1316 8.71127 31.5268C8.21918 31.7976 7.94147 32.3057 7.94147 32.8299C7.94147 33.0717 8.00021 33.3166 8.12533 33.544C10.6354 38.1109 13.6483 41.5182 17.08 43.6697C20.987 46.1195 25.4509 46.961 30.3551 46.1844L88.8275 92.7504C89.9887 93.6758 91.5772 93.9283 93.2976 93.4622ZM60.73 45.4939L49.5041 57.6376L32.9384 44.4449L46.2792 30.0131L60.73 45.4939Z" fill="black"/>
    </svg>
)

type PaintBrushProps = {
    onClick: () => void
    isActive: boolean
}

const PaintBrush = ({onClick, isActive}: PaintBrushProps) => {

    const currentColor = useAppSelector((state) => getCurrentColor(state.paint))

    return (
        <div onClick={onClick} className={style.currentColorItem}>
            {isActive ?
                (<svg width="100" height="94" viewBox="0 0 100 94" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.currentColorItemSVG}>
                    <path
                        d="M30.1756 44.7684L49.6505 60.2782L90.9767 93.1885C91.8106 93.8538 92.9878 93.9774 94.2299 93.6409C95.4704 93.306 96.7796 92.5041 97.8775 91.317C100.072 88.9419 100.655 85.8497 99.2004 84.2926L63.1515 45.674L46.163 27.4748L30.1756 44.7684Z"
                        fill="#FFD061"/>
                    <path
                        d="M95.5338 93.1236C96.5661 91.1751 96.6233 89.1342 95.5293 87.9608L41.6542 32.3523L46.1624 27.4748L63.15 45.6748L99.199 84.2933C100.654 85.8505 100.07 88.9419 97.8768 91.3154C97.1604 92.0913 96.3517 92.7032 95.5338 93.1236Z"
                        fill="#EF8930"/>
                    <path
                        d="M49.6512 60.279L30.1756 44.7684L41.6556 32.3523L46.1638 27.4748L63.1514 45.6748L58.9575 50.2112L49.6512 60.279Z"
                        fill="#E6E6E6"/>
                    <path
                        d="M46.1645 27.4748C49.9701 16.2878 38.8914 4.41261 25.3278 4.94514C11.765 5.47767 4.74059 4.70252 -0.00032964 0.320188C-0.00032964 0.320188 2.33427 49.9869 30.1763 44.7692"
                        fill={currentColor}/>
                    <path
                        d="M39.5437 34.6342L46.163 27.474C49.9686 16.2886 38.8906 4.41108 25.3294 4.9459C11.765 5.47844 4.73986 4.70176 -0.000295405 0.320188C11.6811 15.9788 33.1999 9.59145 39.5437 34.6342Z"
                        fill="black" fillOpacity="0.1"/>
                    <path d="M58.9575 50.2112L41.6556 32.3523L46.1638 27.4748L63.1514 45.6748L58.9575 50.2112Z"
                          fill="#CCCCCC"/>
                </svg>) : inactiveBrush
            }
        </div>
    );
};

export default PaintBrush;